import React, { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useParams } from 'react-router-dom';
import { Group, Title, Text, Stack } from '@mantine/core';
import { RequestCompletion } from '../../components/Completion/RequestCompletion';
import { FormViewer } from '../../components/formbuilder/FormViewer';
import { useOrganisationContext } from '../../hooks/useOrganisation';
import { useApi } from '../../hooks/useApi';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { ApplicationDetails } from '../../models/ApplicationDetails';
import { InteractionType } from '@azure/msal-browser';
import { createStyles } from '@mantine/emotion';
import { IconMessages } from '@tabler/icons-react';

const useStyles = createStyles(() => ({
  maxWidth: {
    width: '100%',
  },
}));

export const ApplicationView: React.FC = () => {
  const { applicationId } = useParams();
  const organisation = useOrganisationContext();
  const api = useApi();
  const isAuthenticated = useIsAuthenticated();
  const [application, setApplication] = useState<ApplicationDetails>();
  const [showCompletionComments, setShowCompletionComments] = useState(false);

  const { classes, theme } = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      if (organisation && applicationId) {
        const response = await api.getApplication(organisation.id, applicationId);

        setApplication(response);
      }
    };

    if (isAuthenticated) {
      fetchData();
    }
  }, [organisation, isAuthenticated]);

  const fetchApplication = async () => {
    if (organisation && applicationId) {
      const applicationResponse = await api.getApplication(organisation.id, applicationId);
      setApplication(applicationResponse);
    }
  };

  const closeCompletionCommentsDrawer = () => {
    setShowCompletionComments(false);
  };

  const hasCompletionComments =
    application?.completionComments && application.completionComments.length > 0;

  return (
    <GrantMeContainer>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {organisation && application && (
          <div className={classes.maxWidth}>
            <Stack>
              <Group justify={'apart'}>
                <Title order={3}>{application.applicationPeriodTitle}</Title>
                {hasCompletionComments && (
                  <Group
                    // noWrap={true} !CHECK!
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowCompletionComments(true)}>
                    <Text fw={'bold'} color={theme.colors[theme.primaryColor][7]}>
                      Läs meddelanden
                    </Text>
                    <IconMessages size={32} color={theme.colors[theme.primaryColor][7]} />
                  </Group>
                )}
              </Group>
              <FormViewer json={JSON.stringify(application.json)} showPageLabels />
            </Stack>

            {hasCompletionComments && (
              <RequestCompletion
                organisationId={organisation?.id}
                application={application}
                onCompletionRequested={fetchApplication}
                showCompletionComments={showCompletionComments}
                onCloseCompletionComments={closeCompletionCommentsDrawer}
              />
            )}
          </div>
        )}
      </MsalAuthenticationTemplate>
    </GrantMeContainer>
  );
};
