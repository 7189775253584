import { Group, Text, useMantineTheme } from '@mantine/core';
import { FC } from 'react';
import { t } from 'ttag';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { IconInfoCircle } from '@tabler/icons-react';

export const ViewerIdentityNumberPerson: FC<ViewerFormElementComponentProps> = ({
  number,
  element,
}) => {
  const theme = useMantineTheme();
  const { identityNumber, missingIdentityNumber } = element.value;

  return (
    <ViewerInputWrapper number={number} element={element}>
      <Text size="sm" style={{ whiteSpace: 'pre-wrap' }}>
        {identityNumber}
      </Text>
      {missingIdentityNumber && (
        <Group align={'center'} gap={4}>
          <IconInfoCircle size={12} color={theme.colors.orange[5]} />
          <Text size={'xs'} color={theme.colors.orange[5]}>{t`Saknar svenskt personnummer`}</Text>
        </Group>
      )}
    </ViewerInputWrapper>
  );
};
