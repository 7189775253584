import { Select } from '@mantine/core';
import { FC } from 'react';
import { FormElementOption } from '../../models/FormElementOption';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { ViewerDropdown } from './ViewerDropdown';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormLabel } from '../Common/FormLabel';
import { FormElementWithControlQuestions } from '../../models/FormElementWithControlQuestion';

const mapOptions = (option: FormElementOption) => {
  const { label, id } = option;
  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  return { label: labelText, value: id };
};

type DropDownProps = {
  element: FormElementWithControlQuestions
} & FormElementComponentProps;

export const Dropdown: FC<DropDownProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerDropdown number={number} element={element} />;
  }

  const { description, label, required, options, hideLabel } = element;

  const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const value = element.value as string;
  return (
    <Select
      data={options.map(mapOptions)}
      disabled={mode === FormElementMode.Editor}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'space-between'} />}
      placeholder={hideLabel === undefined ? undefined : labelText}
      value={value}
      error={error}
      required={required}
      withAsterisk={false}
      onChange={(changeValue) => {
        if (onChange) {
          onChange(changeValue ?? '');
        }
      }}
    />
  );
};
