import { Card, Text, Group, Button, Divider, Stack } from '@mantine/core';
import React, { ReactElement, ReactNode } from 'react';
import { isMissing } from 'utilitype';
import { IconAndText } from '../../components/IconAndText/IconAndText';

interface LiitCardProps {
  title: string;
  children: ReactNode;
  onButtonClick: (() => void) | null;
  buttonText: string;
  footerTitle: string;
  footerText: string;
  footerIcon: ReactElement;
}

export const LiitCard: React.FC<LiitCardProps> = ({
  title,
  children,
  onButtonClick,
  buttonText,
  footerText,
  footerTitle,
  footerIcon,
}) => {
  return (
    <Card withBorder shadow="sm" style={{ height: '100%' }}>
      <Card.Section p="md">
        <Text fw={'bold'}>{title}</Text>
      </Card.Section>

      <Card.Section pl="md" pr="md" pb="md">
        {children}
      </Card.Section>

      <Card.Section>
        <Divider />
        <Group justify={'space-between'} align={'center'} p="md">
          <IconAndText icon={footerIcon} title={footerTitle} text={footerText} animate={false} />
          <Button
            disabled={isMissing(onButtonClick)}
            onClick={() => (isMissing(onButtonClick) ? {} : onButtonClick())}>
            {buttonText}
          </Button>
        </Group>
      </Card.Section>
    </Card>
  );
};
