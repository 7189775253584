import React, { PropsWithChildren } from 'react';
import { Footer } from './Footer';
import { createStyles } from '@mantine/emotion';

// const HEADER_HEIGHT = 84;

const useStyles = createStyles(() => ({
  contentContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  flexThis: {
    flex: 1,
  },
}));

export const ContentContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div className={classes.flexThis}>{children}</div>
      <Footer />
    </div>
  );
};
