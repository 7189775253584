import { Box, Drawer, DrawerProps } from '@mantine/core';
import React, { ReactElement } from 'react';
import { isPresent } from 'utilitype';
import { HEADER_HEIGHT } from '../../layout/Layout';

type LiitDrawerProps = {
  children?: React.ReactNode;
  actions?: ReactElement;
  zeroPaddingRight?: boolean;
} & DrawerProps;

export const LiitDrawer: React.FC<LiitDrawerProps> = ({
  children,
  zeroPaddingRight = false,
  ...rest
}) => {

  const { padding } = rest;
  const otherPadding = isPresent(padding) ? padding : 'xl';
  const paddingRight = isPresent(padding) ? padding : zeroPaddingRight ? 0 : 'xl';

  return (
    <Drawer
      zIndex={199}
      // styles={(theme) => ({
      //   drawer: {
      //     display: 'flex',
      //     flexDirection: 'column',
      //     height: `calc(100%-${HEADER_HEIGHT})`,
      //     marginTop: HEADER_HEIGHT,
      //     borderTopLeftRadius: '4px',
      //     borderBottomLeftRadius: '4px',
      //   },
      //   header: {
      //     paddingTop: theme.spacing.xl, 
      //     paddingLeft: theme.spacing.xl, 
      //     paddingRight: theme.spacing.xl, 
      //   },
      //   body: {
      //     maxHeight: 'unset',
      //     display: 'flex',
      //     flexDirection: 'column',
      //     flexGrow: 1,
      //     minHeight: 0,  
      //   },
      // })} !CHECK!
      overlayProps={{ opacity: 0.35, blur: 1 }}
      position={'right'}
      withCloseButton={false}
      padding={0}
      {...rest}>
      <Box
        pt={otherPadding}
        pb={otherPadding}
        pl={otherPadding}
        pr={paddingRight}
        // sx={{ flexGrow: 1, display: 'flex', overflowY: 'auto' }} !CHECK!
        >
        {children}
      </Box>
    </Drawer>
  );
};
