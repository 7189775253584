import { TextInput, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import React, { BaseSyntheticEvent, useEffect, useState } from 'react';
import { isPresent } from 'utilitype';
import { banks } from '../../../utils/banks';
import { formatClearingNumber } from '../../../utils/textFormatters';
import { ApplicantFormFields } from '../../../components/formbuilder/FormRunner';

interface InputClearingNumberProps {
  label: string;
  form: UseFormReturnType<ApplicantFormFields>;
}

export const InputClearingNumber: React.FC<InputClearingNumberProps> = ({ label, form }) => {
  const [bank, setBank] = useState<string>('');
  const { clearingNumber } = form.values;

  useEffect(() => {
    const number = Number(clearingNumber);
    if (isNaN(number)) {
      setBank('');
      return;
    }

    if (number < 1000 || number > 9999) {
      setBank('');
      return;
    }

    const result = banks.find((b) =>
      b.clearingRanges.some((ranges) => ranges.from <= number && ranges.to >= number),
    );

    setBank(isPresent(result) ? result.name : '');
  }, [form.values.clearingNumber]);

  return (
    <TextInput
      label={label}
      maxLength={16}
      required
      // styles={{ rightSection: { width: 'fit-content', paddingRight: '8px' } }} !CHECK!
      {...form.getInputProps('clearingNumber')}
      onInput={(event: BaseSyntheticEvent<InputEvent>) => {
        const { value } = event.target;
        event.target.value = formatClearingNumber(value);
      }}
      rightSection={
        <Text size={'xs'} fw={700} c={'dimmed'}>
          {bank}
        </Text>
      }
    />
  );
};
