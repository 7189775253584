import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { getErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';

export const validateTextArea = (element: FormElement): ValidationResult => { 
  const { required } = element;

  if (required && !isPresent(element.value)) {
    return { isValid: false, errorMessage: getErrorMessages('IS_REQUIRED') };
  }

  return { isValid: true, errorMessage: '' };
};