import { Grid, Space, Title } from '@mantine/core';
import React from 'react';
import { t } from 'ttag';
import { ApplicationDetails } from '../../../models/ApplicationDetails';
import { PaymentOptions } from '../../../models/PaymentOptions';
import { WayOfPayment } from '../../../models/WayOfPayment';
import { getFormLabel } from './formLabels';
import { TextFieldViewer } from './TextFieldViewer';

interface ApplicantViewerOrganizationProps {
  application: ApplicationDetails;
}

export const ApplicantViewerOrganization: React.FC<ApplicantViewerOrganizationProps> = ({
  application,
}) => {
  const { wayOfPayment, applicantType } = application;

  return (
    <div>
      <Grid gutter={'xs'}>
        <Grid.Col span={{ lg: 12 }}>
          <Title order={4}>Sökande</Title>
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer
            label={getFormLabel('organizationName')}
            value={application.applicantOrganizationName}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer
            label={getFormLabel('identityNumber', applicantType)}
            value={application.applicantIdentityNumber}
          />
        </Grid.Col>

        {application.paymentOptions == PaymentOptions.Default && (
          <>
            {wayOfPayment === WayOfPayment.BankAccount && (
              <>
                <Grid.Col span={{ lg: 4 }}>
                  <TextFieldViewer
                    label={getFormLabel('clearingNumber')}
                    value={application.applicantClearingNumber}
                  />
                </Grid.Col>
                <Grid.Col span={{ lg: 4 }}>
                  <TextFieldViewer
                    label={getFormLabel('accountNumber')}
                    value={application.applicantAccountNumber}
                  />
                </Grid.Col>
              </>
            )}
            {(wayOfPayment === WayOfPayment.PlusGiro || wayOfPayment === WayOfPayment.BankGiro) && (
              <Grid.Col span={{ lg: 8 }}>
                <TextFieldViewer
                  label={getFormLabel(
                    wayOfPayment === WayOfPayment.PlusGiro ? 'pgNumber' : 'bgNumber',
                  )}
                  value={
                    wayOfPayment === WayOfPayment.PlusGiro
                      ? application.applicantPgNumber
                      : application.applicantBgNumber
                  }
                />
              </Grid.Col>
            )}
          </>
        )}
      </Grid>
      <Space h={32} />
      <Title order={4}>{t`Kontaktperson`}</Title>
      <Space h={'lg'} />
      <Grid gutter={'xs'}>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer
            label={getFormLabel('contactPersonFirstName')}
            value={application.contactPersonFirstName}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer
            label={getFormLabel('contactPersonLastName')}
            value={application.contactPersonLastName}
          />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer label={getFormLabel('phone')} value={application.contactPersonPhone} />
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <TextFieldViewer label={getFormLabel('email')} value={application.contactPersonEmail} />
        </Grid.Col>
      </Grid>
    </div>
  );
};
