import { Container, ContainerProps, MantineSize, Space } from '@mantine/core';
import React from 'react';
import { isPresent } from 'utilitype';

interface GrantMeContainerProps extends ContainerProps {
  padding?: number;
  size?: MantineSize;
  children: React.ReactNode;
}

export const GrantMeContainer: React.FC<GrantMeContainerProps> = ({
  padding,
  size,
  children,
  className,
}) => {
  const cs = isPresent(size) ? size : 'lg';
  const p = isPresent(padding) ? padding : 32;

  return (
    <>
      {p != 0 && <Space h={p} />}
      <Container size={cs} className={className}>
        {children}
      </Container>
    </>
  );
};
