import { ToolboxItems } from '../FormBuilderToolbox/ToolBoxItems';
import { FormElement } from '../models/FormElement';
import { v4 as uuidv4, NIL as NIL_UUID } from 'uuid';
import { FormElementType } from '../models/FormElementType';
import { t } from 'ttag';
import { LanguageCode } from '../../../models/LanguageCode';
import { FormElementTextArea } from '../models/FormElementTextArea';
import { FormElementRepeater } from '../models/FormElementRepeater';
import { FormElementWithControlQuestions } from '../models/FormElementWithControlQuestion';
import { WayOfPayment } from '../../../models/WayOfPayment';
import { FormElementWayOfPayment } from './WayOfPaymentElement/WayOfPaymentElement';

export const defaultWayOfPayments = [WayOfPayment.BankAccount, WayOfPayment.BankGiro, WayOfPayment.PlusGiro];

export const getDefaults = (elementType: FormElementType, defaultValue: unknown = null) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: defaultValue,
    size: 12,
    helpText: null,
  } as FormElement);

export const getWayOfPaymentDefaults = (elementType: FormElementType, defaultValue: unknown = null) => 
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: defaultValue,
    size: 12,
    labelAndTextPosition: 'left',
    allowedPaymentMethods: defaultWayOfPayments,
  } as FormElementWayOfPayment);

export const getTextAreaDefaults = (elementType: FormElementType) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: '',
    minRows: 1,
    maxRows: 1,
    size: 12,
    helpText: null,
    maxCharCount: null,
  } as FormElementTextArea);

export const getElementWithOptionsDefaults = (
  elementType: FormElementType,
  defaultValue: string | null | [] | Record<string, number> = NIL_UUID,
) => ({
  id: uuidv4(),
  label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
  type: elementType,
  required: false,
  description: [{ language: LanguageCode.sv, text: '' }],
  options: [
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 1` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 2` }] },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 3` }] },
  ],
  value: defaultValue,
  size: 12,
  helpText: null,
});

export const getElementWithOptionsAndControlQuestionDefaults = (
  elementType: FormElementType,
  defaultValue: string | null | [] | Record<string, number> = NIL_UUID,
) => ({
  id: uuidv4(),
  label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
  type: elementType,
  required: false,
  description: [{ language: LanguageCode.sv, text: '' }],
  options: [
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 1` }], isMandatory: false },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 2` }], isMandatory: false },
    { id: uuidv4(), label: [{ language: LanguageCode.sv, text: t`Val 3` }], isMandatory: false },
  ],
  value: defaultValue,
  size: 12,
  helpText: null,
  controlQuestionErrorText: null,
} as FormElementWithControlQuestions);

export const getRepeaterDefaults = (elementType: FormElementType, defaultValue: unknown = null) =>
  ({
    id: uuidv4(),
    label: [{ language: LanguageCode.sv, text: ToolboxItems[elementType].label }],
    type: elementType,
    required: false,
    description: [{ language: LanguageCode.sv, text: '' }],
    value: [],
    options: [],
    template: [],
    size: 12,
    helpText: null,
    addButtonText: [{ language: LanguageCode.sv, text: 'Lägg till' }],
    labelsOnFirstGroup: false,
    sumNumberFields: false,
    maxItems: 100,
  } as FormElementRepeater);
