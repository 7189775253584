import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { getErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';
import { ContactPersonValue } from './ContactPersonValue';

export const validateContactPerson = (element: FormElement): ValidationResult => { 
  const typedValue = element.value as ContactPersonValue;

  if (!typedValue.isByProxy) {
    return { isValid: true, errorMessage: '' };
  }
  
  if (!isPresent(typedValue.contactPersonFirstName.trim()) || !isPresent(typedValue.contactPersonLastName.trim())) {
    return { isValid: false, errorMessage: getErrorMessages('CONTACT_PERSON_REQUIRED') };
  }

  return { isValid: true, errorMessage: '' };
};