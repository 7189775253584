import { isEmpty, isMissing } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { FormElement } from '../../models/FormElement';
import { getErrorMessages } from '../../../../utils/errorMessages';

export const validateCheckboxGroup = (element: FormElement): ValidationResult => {
  const { required } = element;

  if (required && (isMissing(element.value as string[]) || isEmpty(element.value as string[]))) {
    return { isValid: false, errorMessage: getErrorMessages('IS_REQUIRED') };
  }
 
  if (required) {
    const { options } = element;
    const optionIds = options.map((o) => o.id);
    const values = element.value as string[];

    const results = values.map((value) => optionIds.includes(value));

    if (!results.every((result) => result === true)) {
      return { isValid: false, errorMessage: getErrorMessages('IS_REQUIRED') };
    }
  }

  return { isValid: true, errorMessage: '' };
};