import { FC } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { PageNotFoundView } from './features/not-found/PageNotFoundView';
import { Layout } from './layout/Layout';
import { ProfileView } from './features/profile/ProfileView';
import { ApplicationFormView } from './features/applications/ApplicationFormView';
import { StartView } from './features/landing-page/StartView';
import { ApplicationView } from './features/applications/ApplicationView';
import { ApplicationList } from './features/applications/ApplicationList';
import { ApplicationPeriodsView } from './features/application-periods/application-periods-view';
import { RequisitionView } from './features/applications/RequisitionView';
import { PrivacyView } from './features/policies/privacy-view';
import { FacebookDataDeletionView } from './features/policies/FacebookDataDeletionView';
import { TermsOfServiceView } from './features/policies/tos-view';
import { ApplicationPeriodView } from './features/application-periods/application-period-view';


type RouterProps = {
  setCustomColor: React.Dispatch<React.SetStateAction<string>>
};

export const Router: FC<RouterProps> = ({ setCustomColor }) => {
  return (
    <Routes>
      <Route path="/" element={<StartView />} />
      <Route path="policies/privacy" element={<PrivacyView />} />
      <Route path="policies/fb_data_deletion" element={<FacebookDataDeletionView />} />
      <Route path="policies/tos" element={<TermsOfServiceView />} />
      <Route path=":organisationSlug" element={<Layout setCustomColor={setCustomColor} />}>
        <Route path="" element={<ApplicationPeriodsView />} />
        <Route path="applicationperiods" element={<ApplicationPeriodsView />} />
        <Route path="applicationperiods/:applicationPeriodId" element={<ApplicationPeriodView />} />
        <Route path="applications/:applicationPeriodId/new" element={<ApplicationFormView />} />
        <Route path="applications/:applicationId/edit" element={<ApplicationFormView />} />
        <Route path="applications" element={<ApplicationList />} />
        <Route path="applications/:applicationId" element={<ApplicationView />} />
        <Route path="applications/:applicationId/requisition" element={<RequisitionView />} />
        <Route path="profile" element={<ProfileView />} />
        <Route path="*" element={<PageNotFoundView />} />
      </Route>
    </Routes>
  );
};
