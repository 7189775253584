import { ActionIcon, Group, Loader, ScrollArea, useMantineTheme } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import { Document } from 'react-pdf/dist/esm/entry.webpack';
import { motion } from 'framer-motion';
import cls from 'classnames';
import { PDFViewerControls } from './PDFViewerControls';
import { useStyles } from './Styles';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { download } from '../../utils/download';
import { IconDownload } from '@tabler/icons-react';

interface PDFViewerProps {
  getData: () => Promise<string | null>;
  filename: string;
}

// CONTROLLS FOR VIEWER
// const ZOOM_STEP = 0.2;
// const ZOOM_MIN = 1.0;
// const ZOOM_MAX = 2.6;
// const BUTTON_SIZE = 'lg';
// const HIDE_BUTTONS_TIMER = 1500;

export const PDFViewer: React.FC<PDFViewerProps> = ({ getData, filename }) => {
  const [objectUrl, setObjectUrl] = useState<string>('');
  const [pages, setPages] = useState(0);
  const { classes } = useStyles();
  // const [scale, setScale] = useState(1.0);
  // const [rotation, setRotation] = useState(0);
  // const [showButtons, setShowButtons] = useState(true);
  // const [mouseOverButton, setMouseOverButton] = useState(false);
  // const theme = useMantineTheme();

  // const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    getData()
      .then((data) => {
        if (data) {
          setObjectUrl(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);

  //   return () => clearTimeout(timer.current ?? undefined);
  // }, []);

  // const mouseMove = () => {
  //   clearTimeout(timer.current ?? undefined);
  //   setShowButtons(true);
  //   if (!mouseOverButton) {
  //     timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
  //   }
  // };

  // const touchMe = () => {
  //   clearTimeout(timer.current ?? undefined);
  //   setShowButtons(true);
  //   timer.current = setTimeout(() => setShowButtons(false), HIDE_BUTTONS_TIMER);
  // };

  const loadingUI = (
    <Group align={'center'} pt={'xl'}>
      <Loader size={'xl'} />
    </Group>
  );

  if (!objectUrl) {
    return loadingUI;
  }

  return (
    // <div onMouseMove={mouseMove} onTouchStart={touchMe} className={classes.pdfWrapper}>
    <div className={classes.pdfWrapper}>
      <ScrollArea style={{ height: '100%' }} pl={'sm'} pb={'md'} pt={'md'} pr={'sm'}>
        <Document
          loading={loadingUI}
          file={objectUrl}
          onLoadSuccess={async (pdf) => {
            setPages(pdf?.numPages ?? 0);
          }}>
          {Array.from({ length: pages }, (_, index) => {
            return (
              <Page
                rotate={0}
                scale={1}
                loading={''}
                width={800}
                key={'pages_' + index}
                pageIndex={index}
                pageNumber={index + 1}
              />
            );
          })}
        </Document>
      </ScrollArea>
      {/* <motion.div
        animate={showButtons ? 'show' : 'hidden'}
        variants={{
          show: { opacity: 1, transition: { duration: 0.1 } },
          hidden: { opacity: 0, transition: { duration: 1 } },
        }}>
        <div
          className={classes.controllsDownload}
          onMouseEnter={() => setMouseOverButton(true)}
          onMouseLeave={() => setMouseOverButton(false)}>
          <ActionIcon
            color={theme.primaryColor}
            variant={'light'}
            size={BUTTON_SIZE}
            onClick={() => download(objectUrl ?? '', filename)}>
            <IconDownload />
          </ActionIcon>
        </div>
        <div
          className={cls(classes.controlls)}
          onMouseEnter={() => setMouseOverButton(true)}
          onMouseLeave={() => setMouseOverButton(false)}>
          <Group justify={'center'}>
            <div className={classes.controllPanel}>
              <PDFViewerControls
                buttonSize={BUTTON_SIZE}
                onRotateLeft={() => setRotation((prev) => (prev % 360) - 90)}
                onRotateRight={() => setRotation((prev) => (prev % 360) + 90)}
                onZoomOut={() => setScale((prev) => (prev > ZOOM_MIN ? prev - ZOOM_STEP : prev))}
                onZoomIn={() => setScale((prev) => (prev <= ZOOM_MAX ? prev + ZOOM_STEP : prev))}
                onZoomReset={() => setScale(1.0)}
                disableZoomOut={scale <= ZOOM_MIN}
                disableZoomIn={scale >= ZOOM_MAX}
                disableZoomReset={scale === 1.0}
              />
            </div>
          </Group>
        </div>
      </motion.div> */}
    </div>
  );
};
