import {
  // Header,
  Anchor,
  Group,
  Burger,
  Space,
  Menu,
  ActionIcon,
  Text,
  Container,
  Image,
  Title,
  Drawer,
  Flex,
  Button,
} from '@mantine/core';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from '@azure/msal-react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { useOrganisationContext } from '../hooks/useOrganisation';
import { loginRequest } from '../AuthConfig';
import { getLocale, setLocale } from '../i18nInit';
import { GBFlag, SEFlag } from 'mantine-flagpack';
import { t } from 'ttag';
import { HEADER_HEIGHT } from './Layout';
import { createStyles } from '@mantine/emotion';
import { IconUser, IconSettings, IconLogout, IconLogin } from '@tabler/icons-react';

const useStyles = createStyles((theme, _, u) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: 'flex',
    alignItems: 'center',
    gap: 16,
  },

  burger: {
    [u.largerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },

  links: {
    height: HEADER_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    [u.smallerThan(theme.breakpoints.sm)]: {
      display: 'none',
    },
  },

  title: {
    color: theme.colors.dark[8],
    '&:hover': {
      textDecoration: 'none',
    },
  },

  mainLinks: {
    marginRight: -theme.spacing.sm,
  },

  mainLink: {
    fontSize: 14,
    color: theme.colors.dark[8],
    padding: `7px ${theme.spacing.sm}px`,
    fontWeight: 700,
    borderBottom: '2px solid transparent',
    transition: 'border-color 100ms ease, color 100ms ease',
    letterSpacing: '1px',

    '&:hover': {
      color: theme.colors.dark[5],
      textDecoration: 'none',
    },
  },

  mainLinkActive: {
    color: theme.colors.dark[8],
    borderBottomColor: theme.colors[theme.primaryColor][7],
  },

  header: {
    height: HEADER_HEIGHT,
    border: 0,
    background: theme.white,
    position: 'static',
    borderBottom: `1px solid ${theme.colors.gray[3]}`,
  },
}));

interface LinkProps {
  label: string;
  link: string;
  requireAuthentication: boolean;
}

interface MenuHeaderProps {
  mainLinks: LinkProps[];
}

export const MenuHeader = ({ mainLinks }: MenuHeaderProps) => {
  const [opened, handlers] = useDisclosure(false);
  const { classes, cx, theme } = useStyles();
  const navigate = useNavigate();
  const organisation = useOrganisationContext();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { organisationSlug } = useParams();
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const location = useLocation();

  const toggleLocale = () => {
    if (getLocale() === 'sv') {
      setLocale('en');
    } else {
      setLocale('sv');
    }

    window.location.reload();
  };

  const mainItems = mainLinks
    .filter((item) => item.requireAuthentication === isAuthenticated || !item.requireAuthentication)
    .map((item) => (
      <Anchor<'a'>
        href={item.link}
        key={item.label}
        className={cx(classes.mainLink, {
          [classes.mainLinkActive]: location.pathname
            .replace(organisationSlug ?? '', '')
            .startsWith(item.link.replace(organisationSlug ?? '', '')),
        })}
        onClick={(event) => {
          event.preventDefault();
          navigate(item.link);
          if (opened) {
            handlers.toggle();
          }
        }}>
        {item.label}
      </Anchor>
    ));

  const getLocaleMenuItem = () => {
    if (getLocale() === 'sv') {
      return (
        <Menu.Item
          leftSection={<GBFlag style={{ width: 16 }} radius={'xs'} title="English" />}
          onClick={() => toggleLocale()}>
          {t`English`}
        </Menu.Item>
      );
    }

    return (
      <Menu.Item
        leftSection={<SEFlag style={{ width: 16 }} radius={'xs'} title="Svenska" />}
        onClick={() => toggleLocale()}>
        {t`Svenska`}
      </Menu.Item>
    );
  };

  const getDropDownMenu = () => {
    return (
      <Menu position="bottom" withArrow shadow={'xl'}>
        <Menu.Target>
          <ActionIcon variant={'subtle'}>
            <IconUser  size={32} />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <AuthenticatedTemplate>
            <Menu.Label>
              {instance.getActiveAccount()?.idTokenClaims?.given_name?.toString()}{' '}
              {instance.getActiveAccount()?.idTokenClaims?.family_name?.toString()}
            </Menu.Label>
            {getLocaleMenuItem()}
            <Menu.Item
              leftSection={<IconSettings size={14} />}
              onClick={() => navigate(`/${organisationSlug}/profile`)}>
              Min sida
            </Menu.Item>
            <Menu.Item
              leftSection={<IconLogout size={14} />}
              onClick={() => {
                instance.logoutRedirect();
              }}>
              Logga ut
            </Menu.Item>
          </AuthenticatedTemplate>

          <UnauthenticatedTemplate>
            {getLocaleMenuItem()}
            <Menu.Item
              leftSection={<IconLogin size={14} />}
              onClick={() => {
                instance.loginRedirect(loginRequest);
              }}>
              Logga in
            </Menu.Item>
          </UnauthenticatedTemplate>
        </Menu.Dropdown>
      </Menu>
    );
  };

  return (
    <div className={classes.header}>
      <Container size="lg" className={classes.inner}>
        <Burger
          opened={opened}
          onClick={() => handlers.toggle()}
          className={classes.burger}
          size="sm"
        />
        <Group w={'100%'} justify={'space-between'} align={'center'}>
          <Anchor component={Link} to={`/${organisationSlug}`} className={classes.title}>
            <Group>
              {organisation?.logotypeUrl && <Image src={organisation?.logotypeUrl}></Image>}
              {!organisation?.logotypeUrl && (
                <Title fw={'bold'} order={3}>
                  {organisation?.name}
                </Title>
              )}
            </Group>
          </Anchor>
          <div className={classes.links}>
            <Group fw={0} align="right" className={classes.mainLinks} pr={'xl'}>
              {mainItems}
              <Space w="xl" />
              {getDropDownMenu()}
            </Group>
          </div>
        </Group>
      </Container>
      {isSmallScreen && (
        <Drawer
          padding={16}
          withCloseButton={false}          
          size={280}
          opened={opened}
          onClose={() => {
            handlers.toggle();
          }}
          position={'left'}>
          <AuthenticatedTemplate>
            <Flex direction={'column'} align={'space-between'} style={{ flex: 1 }}>
              <Flex style={{ flex: 1 }} direction={'column'}>
                {mainItems}
              </Flex>
              <Flex direction={'column'}>
                <Text size={'xs'} color={'dimmed'} ta={'right'} fw={500}>
                  {instance.getActiveAccount()?.idTokenClaims?.given_name?.toString()}{' '}
                  {instance.getActiveAccount()?.idTokenClaims?.family_name?.toString()}
                </Text>
                <Group align={'right'}>
                  <Flex
                    style={{ cursor: 'pointer' }}
                    align={'center'}
                    color={'red'}
                    gap={8}
                    onClick={() => {
                      instance.logoutRedirect();
                    }}>
                    <IconLogout size={16} color={theme.colors[theme.primaryColor][7]} />
                    <Text
                      size={'sm'}
                      color={theme.colors[theme.primaryColor][7]}>{t`Logga ut`}</Text>
                  </Flex>
                </Group>
              </Flex>
            </Flex>
          </AuthenticatedTemplate>
          <UnauthenticatedTemplate>
            <Flex style={{ flex: 1 }} align={'center'}>
              <Button
                fullWidth
                onClick={() => {
                  instance.loginRedirect(loginRequest);
                }}
                leftSection={<IconLogin size={16} />}>{t`Logga in`}</Button>
            </Flex>
          </UnauthenticatedTemplate>
        </Drawer>
      )}
    </div>
  );
};
