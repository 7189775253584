import { Input, Group, FileButton, Text, Button, LoadingOverlay } from '@mantine/core';
import { FC, useEffect, useRef, useState } from 'react';
import { t } from 'ttag';
import { LanguageCode } from '../../../../models/LanguageCode';
import { UploadedFile } from '../../../../models/UploadedFile';
import LanguageHandler from '../../../../utils/languageHandler';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FileDelete } from '../../models/FileDelete';
import { FileUpload } from '../../models/FileUpload';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';
import { ViewerFileBox } from './ViewerFileBox';

type FileBoxProps = {
  element: FormElement;
} & FormElementComponentProps;

export const FileBox: FC<FileBoxProps> = ({ number, element, mode, error, onChange }) => {
  const [file, setFile] = useState<UploadedFile | null>(null);
  const [overlayVisible, setOverlayVisible] = useState(false);

  useEffect(() => {
    if (element.value) {
      setFile(element.value as UploadedFile);
    }
  }, []);

  if (mode === FormElementMode.Viewer) {
    return <ViewerFileBox number={number} element={element} />;
  }

  const { description, required, id, hideLabel } = element;
  const resetRef = useRef<() => void>(null);
  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);

  const callback = () => {
    setOverlayVisible(false);
  };

  const deleteFile = () => {
    setOverlayVisible(true);
    setFile(null);
    resetRef.current?.();
    if (onChange) {
      onChange(new FileDelete(id, callback));
    }
  };

  return (
    <Input.Wrapper
      key={id}
      description={descriptionText}
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'start'} />}
      required={required}
      withAsterisk={false}
      error={error}>
      <Group mt="sm">
        <LoadingOverlay visible={overlayVisible} overlayProps={{ blur: 2 }} />
        <FileButton
          accept="image/png,image/jpeg,application/pdf"
          resetRef={resetRef}
          onChange={async (payload) => {
            if (payload) {
              const { name: filename, type, size } = payload;
              if (onChange) {
                console.log('onchange');
                setOverlayVisible(true);
                const reader = new FileReader();
                reader.readAsArrayBuffer(payload);
                reader.onload = () => {
                  const { result } = reader;
                  onChange(
                    new FileUpload(filename, result as ArrayBuffer, type, size, id, callback),
                  );
                };

                setFile({ filename, type, size });
              }
            }
          }}>
          {(props) => {
            if (!file) {
              return (
                <Button size={'xs'} {...props}>
                  {t`Välj fil ...`}
                </Button>
              );
            }

            return (
              <Button size={'xs'} onClick={deleteFile} variant="outline">
                {t`Ta bort fil`}
              </Button>
            );
          }}
        </FileButton>
        {file && (
          <Text size="xs" color={'dimmed'}>
            {file.filename} ({(file.size / 1024 / 1024).toFixed(2)} MB)
          </Text>
        )}
      </Group>
    </Input.Wrapper>
  );
};
