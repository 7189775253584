import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { getErrorMessages } from '../../../../utils/errorMessages';
import { FormElement } from '../../models/FormElement';
import { AmountsTableValue } from './AmountsTableValue';

export const validateAmountsTable = (element: FormElement): ValidationResult => {
  const { required } = element;

  if (required) {
    const values = element.value as AmountsTableValue;
    const isValid = Object.values(element.options).map(option => isPresent(values[option.id]))
      .every((value) => value === true);

    if (!isValid) {
      return { isValid: false, errorMessage: getErrorMessages('IS_REQUIRED') };
    }
  }


  return { isValid: true, errorMessage: '' };
};