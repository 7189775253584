import { Checkbox, Input, TextInput } from '@mantine/core';
import { BaseSyntheticEvent, FC } from 'react';
import { t } from 'ttag';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { formatIdentityNumberSwedish } from '../../../../utils/textFormatters';
import { FormElementComponentProps } from '../../FormElementComponentProps';
import { FormElementMode } from '../../FormElementMode';
import { FormElement } from '../../models/FormElement';
import { FormLabel } from '../Common/FormLabel';
import { IdentityNumberPersonValue } from './IdentityNumberPersonValue';
import { ViewerIdentityNumberPerson } from './ViewerIdentityNumberPerson';

type IdentityNumberPersonProps = {
  element: FormElement;
} & FormElementComponentProps;

export const IdentityNumberPerson: FC<IdentityNumberPersonProps> = ({
  number,
  element,
  mode,
  error,
  onChange,
}) => {
  if (mode === FormElementMode.Viewer) {
    return <ViewerIdentityNumberPerson number={number} element={element} />;
  }

  const typedValue = element.value as IdentityNumberPersonValue;

  const { description, required, hideLabel } = element;

  const descriptionText = LanguageHandler.getTextByLanguage(description, LanguageCode.sv);
  const { identityNumber, missingIdentityNumber } = typedValue;

  return (
    <Input.Wrapper
      label={hideLabel ? undefined : <FormLabel element={element} mode={mode} justify={'space-between'} />}
      description={descriptionText}
      error={error}
      required={required}>
      <TextInput
        error={!!error}
        placeholder={missingIdentityNumber ? '' : t`ÅÅÅÅMMDDXXXX`}
        value={identityNumber ?? ''}
        onInput={(event: BaseSyntheticEvent<InputEvent>) => {
          const { value } = event.target;

          event.target.value = missingIdentityNumber ? value : formatIdentityNumberSwedish(value);
        }}
        onChange={(event) => {
          if (onChange) {
            onChange({ ...typedValue, identityNumber: event.currentTarget.value });
          }
        }}
        label={undefined}
      />
      <Checkbox
        error={!!error}
        pt={8}
        pl={4}
        label={t`Saknar svenskt personnummer`}
        onChange={(event) => {
          if (onChange) {
            onChange({ ...typedValue, missingIdentityNumber: event.currentTarget.checked });
          }
        }}
        checked={missingIdentityNumber}
      />
    </Input.Wrapper>
  );
};
