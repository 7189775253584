import { isPresent } from 'utilitype';
import { ValidationResult } from '../../../../models/ValidationResult';
import { getErrorMessages } from '../../../../utils/errorMessages';
import Validator from '../../../../utils/validators';
import { FormElement } from '../../models/FormElement';
import { IdentityNumberPersonValue } from './IdentityNumberPersonValue';

export const validateIdentityNumberPerson = (element: FormElement): ValidationResult => { 
  const { missingIdentityNumber, identityNumber } = element.value as IdentityNumberPersonValue;
  
  if (!isPresent(identityNumber)) {
    return { isValid: false, errorMessage: getErrorMessages('IS_REQUIRED') };
  }

  if (missingIdentityNumber) {
    return { isValid: true, errorMessage: '' };
  }
  
  const regExp = new RegExp('^(19|20)[0-9]{10}$', 'g');

  if (!identityNumber.match(regExp)) {
    return { isValid: false, errorMessage: getErrorMessages('FAULTY_IDENTIFIER_FORMAT_PERSON') };
  }

  const isValid = Validator.validateIdentityNumberSwedish(identityNumber);
  if (!isValid) {
    return { isValid: false, errorMessage: getErrorMessages('FAULTY_IDENTIFIER_PERSON') };
  }

  return { isValid: true, errorMessage: '' };
};