import { Text } from '@mantine/core';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { isMissing } from 'utilitype';
import { Organisation } from '../../models/Organisation';
import { ApplicationPeriod } from '../../models/ApplicationPeriod';
import { formatDate } from '../../utils/date';
import { LiitCard } from '../../components/LiitCard/LiitCard';
import { useStyles } from '../../components/PdfViewer/Styles';
import { IconCalendar } from '@tabler/icons-react';
import { t } from 'ttag';

interface ApplicationPeriodCardProps {
  grant: ApplicationPeriod;
  organisation: Organisation;
  isUpcoming: boolean;
}

export const ApplicationPeriodCard: React.FC<ApplicationPeriodCardProps> = ({
  grant,
  organisation,
  isUpcoming: isUpcoming,
}) => {
  const navigate = useNavigate();

  const { id, title, description } = grant;
  const { slug } = organisation;
  const { theme } = useStyles();

  const getDates = () => {
    const from = formatDate(grant.startDate);
    const to = grant.endDate ? formatDate(grant.endDate) : '';

    const result = from + (isMissing(to) ? '' : ` - ${to}`);

    return result;
  };

  return (
    <LiitCard
      title={title}
      onButtonClick={isUpcoming ? null : () => navigate(`/${slug}/applications/${id}/new`)}
      buttonText={t`Ansök`}
      footerTitle={t`Ansökningsperiod`}
      footerText={getDates()}
      footerIcon={<IconCalendar color={theme.colors[theme.primaryColor][9]} />}>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <Text size="sm">{description}</Text>
      </div>
    </LiitCard>
  );
};
