import { getLocale, initLocale } from './i18nInit';
import { Router } from './router';
import { LiitCookieConsent } from './components/LiitCookieConsent/LiitCookieConsent';
import { MantineProvider, MantineThemeOverride, Table } from '@mantine/core';
import { MantineEmotionProvider } from '@mantine/emotion';
import { getCustomColors } from './utils/customColors';
import { useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/notifications/styles.css';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';
import 'dayjs/locale/sv';
import 'dayjs/locale/en';

export const INPUT_MAX_LENGTH = 200;
export const BORDER_COLOR = '#cbd0dd';

const App = () => {
  initLocale();

  const [customColor, setCustomColor] = useState('#a0a0a0');

  const customTheme: MantineThemeOverride = {
    colors: getCustomColors(customColor ?? '#a0a0a0'),
    primaryColor: 'brand',
    primaryShade: { light: 7, dark: 4 },
    defaultRadius: 'sm',
    defaultGradient: {
      from: 'orange',
      to: 'red',
      deg: 45,
    },
    fontFamily: `${"'Inter Tight', sans-serif"}`,
    headings: { fontFamily: `${"'Inter Tight', sans-serif"}` },
    shadows: {
      sm: 'unset',
    },
    components: {
      Stepper: {
        styles: (theme) => ({
          step: {
            icon: {
              background: 'blue',
            },
          },
        }),
      },
      Tabs: {
        styles: (theme) => ({
          tab: {
            color: theme.colors[theme.primaryColor][7],
            fontWeight: 600,
            '&[dataActive]': {
              color: theme.colors[theme.primaryColor][7],
            },
          },
          tabsList: {
            border: '0px',
          },
        }),
      },
      TextInput: {
        styles: (theme) => ({
          label: {
            color: theme.colors.dark[9],
            fontWeight: 'bold',
            display: 'inline-flex',
          },
        }),
      },
      InputWrapper: {
        styles: (theme) => ({
          label: {
            color: theme.colors.dark[9],
            fontWeight: 'bold',
            display: 'inline-flex',
            gap: '8px',
            width: '100%',
          },
        }),
      },
      Table: Table.extend({
        vars: (theme) => {
          return {
            table: {},
            th: {
              background: theme.colors.gray[1],
              color: theme.colors.gray[9],
            },
            td: {
              '&:hover': {
                backgroundColor: 'red',
                borderBottom: `1px solid ${BORDER_COLOR}`,
              },
            },
          };
        },
      }),
      Button: {
        styles: {
          label: {
            textTransform: 'uppercase',
          },
        },
      },
      Title: {
        styles: (theme) => ({
          root: {
            //color: theme.colors[theme.primaryColor][9],
            // textTransform: 'uppercase',
          },
        }),
      },
    },
  };

  return (
    <MantineProvider theme={customTheme}>
      <Notifications position="top-center" />
      <MantineEmotionProvider>
        <DatesProvider settings={{ locale: getLocale(), timezone: 'UTC' }}>
          <Router setCustomColor={setCustomColor} />
          <LiitCookieConsent />
        </DatesProvider>
      </MantineEmotionProvider>
    </MantineProvider>
  );
};

export default App;
