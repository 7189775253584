import { showNotification } from '@mantine/notifications';
import { getErrorMessages } from './errorMessages';
import { t } from 'ttag';

const defaultErrorMessage = { title: t`Okänt fel`, message: t`Försök senare.` };

const getErrorMessage = async (response: Response) => {
  if (response.status === 400) {
    const json = await response.json();

    if (json.errorCode) {
      return { title: t`Fel`, message: getErrorMessages(`API_CLIENT_ERROR_${json.errorCode}`) };
    } else {
      return defaultErrorMessage;
    }
  }

  if (response.status === 401) {
    return { title: t`Fel`, message: t`Du saknar behörighet för denna åtgärd.` };
  }

  return defaultErrorMessage;
};

export async function handleError(response?: Response) {
  if (!response) {
    showNotification({
      ...defaultErrorMessage,
      color: 'red',
    });
  } else if (!response.ok) {
    const errorMessage = await getErrorMessage(response);

    showNotification({
      ...errorMessage,
      color: 'red',
    });
  }
}
