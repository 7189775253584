import { Group, Title, Paper, Box, Space } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { t } from 'ttag';

export const RequisitionCompleted: React.FC = () => {
  return (
    <GrantMeContainer>
      <div
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <motion.div
          animate={{
            scale: [0, 1.1, 1],
          }}
          transition={{
            duration: 1,
            times: [0, 0.4, 0.8],
            ease: 'easeInOut',
          }}>
          <Box maw={600}>
            <Paper p={'xl'} pt={48}>
              <Group justify={'center'}>
                <Title order={2}>{t`Ansökan är rekvirerad`}</Title>
              </Group>
              <Space h={24} />
            </Paper>
          </Box>
        </motion.div>
      </div>
    </GrantMeContainer>
  );
};
