import { createStyles } from '@mantine/emotion';

export const runnerStyles = createStyles((theme) => ({
  elementsWrapper: {
    display: 'flex',
    width: '100%',
    padding: '8px',
    alignItems: 'center',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: theme.colors.gray[1],
    },
  },
  components: {
    flex: 1,
  },
  buttonsContainer: {
    paddingLeft: '12px',
    paddingRight: '8px',
  },
}));