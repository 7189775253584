import { Group, Title, Paper, Box, Stack, Space, Divider, useMantineTheme } from '@mantine/core';
import { motion } from 'framer-motion';
import React from 'react';
import { GrantMeContainer } from '../../layout/GrantMeContainer';
import { IconAndText } from '../../components/IconAndText/IconAndText';
import { FormElementMode } from '../../components/formbuilder/FormElementMode';
import { t } from 'ttag';
import { ApplicationType } from '../../models/Application';
import { IconSearch, IconMessages, IconGavel } from '@tabler/icons-react';

const ICON_SIZE = 32;

interface ApplicationCompletedProps {
  runnerMode: FormElementMode.Runner | FormElementMode.Completion;
  applicationType: ApplicationType;
}

export const ApplicationCompleted: React.FC<ApplicationCompletedProps> = ({
  runnerMode,
  applicationType,
}) => {
  const theme = useMantineTheme();

  return (
    <GrantMeContainer>
      <div
        style={{
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <motion.div
          animate={{
            scale: [0, 1.1, 1],
          }}
          transition={{
            duration: 1,
            times: [0, 0.4, 0.8],
            ease: 'easeInOut',
          }}>
          <Box maw={600} /*sx={{ maxWidth: 600 }}*/>
            {applicationType === ApplicationType.Application && (
              <Paper p={'xl'} pt={48} withBorder>
                <Group justify={'center'}>
                  <Title order={2}>
                    {runnerMode === FormElementMode.Completion
                      ? t`Tack för din komplettering`
                      : t`Tack för din ansökan`}
                  </Title>
                </Group>
                <Space h={24} />
                <Divider />
                <Group justify={'center'} pt={24}>
                  <Title order={5}>{t`Vad händer nu?`}</Title>
                </Group>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'start',
                  }}>
                  <Box pl={32} pr={32} pt={48} pb={48}>
                    <Stack gap={32}>
                      <IconAndText
                        icon={
                          <IconSearch
                            size={ICON_SIZE}
                            stroke={2}
                            color={theme.colors[theme.primaryColor][9]}
                          />
                        }
                        title={t`Granskning`}
                        text={t`Det kommer ske en granskning av din ansökan`}
                        animationDelay={0.6}
                        animate
                      />
                      <IconAndText
                        icon={
                          <IconMessages
                            size={ICON_SIZE}
                            stroke={2}
                            color={theme.colors[theme.primaryColor][9]}
                          />
                        }
                        title={'Komplettering'}
                        text={
                          runnerMode === FormElementMode.Runner
                            ? t`Behövs det mer information kommer vi att ta kontakt med dig.`
                            : t`Behövs det mer information kommer vi att ta kontakt med dig igen.`
                        }
                        animationDelay={0.8}
                        animate
                      />
                      <IconAndText
                        icon={
                          <IconGavel
                            size={ICON_SIZE}
                            stroke={2}
                            color={theme.colors[theme.primaryColor][9]}
                          />
                        }
                        title={t`Beslut`}
                        text={t`Ett beslut kommer tas och du blir meddelad via mail eller telefon.`}
                        animationDelay={1}
                        animate
                      />
                    </Stack>
                  </Box>
                </div>
              </Paper>
            )}

            {applicationType === ApplicationType.Report && (
              <Paper p={'xl'} pt={48}>
                <Group justify={'center'}>
                  <Title order={2}>{t`Tack för din återrapport`}</Title>
                </Group>
                <Space h={24} />
              </Paper>
            )}
          </Box>
        </motion.div>
      </div>
    </GrantMeContainer>
  );
};
