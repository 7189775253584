import { Text, Group, Divider, Space } from '@mantine/core';
import { FC } from 'react';
import { isPresent } from 'utilitype';
import { LanguageCode } from '../../../../models/LanguageCode';
import LanguageHandler from '../../../../utils/languageHandler';
import { formatValue } from '../../../LiitGrid/FormatValue';
import { PropertyType } from '../../../LiitGrid/PropertyType';
import { ViewerFormElementComponentProps } from '../../FormElementViewerComponentProps';
import { ViewerInputWrapper } from '../Common/FormInputWrapper';
import { AmountsTableValue } from './AmountsTableValue';
import { sumAmountsTableValue } from './SumAmountsTable';
import { t } from 'ttag';

export const ViewerAmountsTable: FC<ViewerFormElementComponentProps> = ({ number, element }) => {
  const { options } = element;
  const value = element.value as AmountsTableValue;

  return (
    <ViewerInputWrapper number={number} element={element}>
      {options.map((option) => {
        const { id, label } = option;
        const labelText = LanguageHandler.getTextByLanguage(label, LanguageCode.sv);

        const formatedValue = isPresent(value[option.id]) ? formatValue(value[option.id], PropertyType.Number) : 0;

        return (
          <Group key={`${id}_tb_${id}`} justify={'space-between'}>
            <Text size={'sm'}>{labelText}</Text>
            <Text size={'sm'}>{formatedValue}</Text>
          </Group>
        );
      })}
      <Space h={'xs'} />
      <Divider pb={'xs'} />
      <Group justify={'space-between'}>
        <Text size={'sm'} fw={600}>
          {t`Totalt`}
        </Text>
        <Text size={'sm'} fw={600}>
          {formatValue(sumAmountsTableValue(value), PropertyType.Number)}
        </Text>
      </Group>
    </ViewerInputWrapper>
  );
};
